

import logo from '../images/logo.png';

let config = {}
//Development
// config['BASE_URL'] = 'http://domain.com/';
// config['API_URL'] = 'http://localhost:8080/v1/admin/';
// config['IMAGE_PATH'] = 'http://localhost:8080/public/uploads/';

//Live
config['BASE_URL'] = 'http://admin-hrms.dtsmis.in/';
config['API_URLS'] = 'http://api-hrms.dtsmis.in:3009/v1/admin/';
config['IMAGE_PATH'] = 'http://api-hrms.dtsmis.in:3009/public/uploads/';
config['API_URL']='http://api-hrms.dtsmis.in:3009/v1/employee/';

config['API_TOKEN'] = 'Bearer 744b365cde7bd714a928d5a04167a117';

config['LOGO_PATH'] = logo;

config['COMPANY_NAME'] = 'HRMS WEB APP';  
config['PANEL_NAME'] = 'HR Login';
config['HELP_URL'] = 'support';
config['PRIVACY_URL'] = 'privacy-policy';
config['TERMS_URL'] = 'terms-conditions';
config['COMPANY_NAME'] = 'HLFPPT Pvt Ltd.';

export default config;